import React from 'react';
import Fade from 'react-reveal/Fade';
import * as emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form from 'elements/Form';
import Button from 'elements/Button';

export default function DiscussForm(props) {
  const { data, resetForm } = props;

  const submitEmail = () => {
    const { name, company, email, phone, projectIdea } = data;

    const templateParams = {
      from_name: name,
      from_company: company,
      from_email: email,
      from_contact: phone,
      message: projectIdea,
      to_name: 'DTT',
    };

    if (name !== '' && company !== '' && email !== '' && phone !== '' && projectIdea !== '') {
      emailjs
        .send(
          'service_6shio2e', // Your service ID
          'template_0kf8djq', // Your template ID
          templateParams,
          'BqAp7wSUQEUaQAFfg' // Your user ID (optional, if you have set up EmailJS user authentication)
        )
        .then(() => {
          toast.success("Success! We'll get back to you soon. Thank you!");
          resetForm();
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          toast.error('Error sending email. Please try again later.');
        });
    } else {
      toast.error('Please fill out the entire form.');
    }
  };

  return (
    <section className="container mx-auto mt-10 grid grid-cols-1 sm:grid-cols-2 gap-8">
      {/* Contact Information Section */}
      <div className="mt-0 ml-16 mr-0 sm:ml-0 sm:mr-5">
        <Fade bottom>
          <h1 className="text-5xl text-theme-blue text-center font-bold">Contact us</h1>{' '}<br></br>
              
          <div className="text-lg text-gray-600 font-light m-1 ml-6">
            <p><b>Mail Id:</b> info@digital-think.in</p> {' '}<br></br>
            <p><b>Address:</b> No 12, Muneshwara Block, Avalahalli, 3rd Cross,<br /> Bengaluru Urban, Karnataka 560027</p>{' '}<br></br>
            <p><b>Contact No:</b> +91 9449726429</p>
          </div>
        </Fade>
      </div>

      {/* Form Section */}
      <div>
        <Fade bottom>
          <div className="flex flex-col mx-auto">
            <h1 className="text-5xl text-theme-blue text-center font-bold">Let's Discuss</h1>

            <p className="font-light text-lg text-gray-400 text-center mb-12">
              Please fill out the form below to discuss your project and we'll get back to you in less than 24 hours.
            </p>

            <div className="flex flex-col space-y-4">
              <Form
                id="name"
                name="name"
                type="text"
                value={data.name}
                placeholder="Your name"
                className=""
                onChange={props.onChange}
              />
              <Form
                id="company"
                name="company"
                type="text"
                value={data.company}
                placeholder="Your company"
                className=""
                onChange={props.onChange}
              />
              <Form
                id="email"
                name="email"
                type="email"
                value={data.email}
                placeholder="Your email address"
                className=""
                onChange={props.onChange}
              />
              <Form
                id="phone"
                name="phone"
                type="tel"
                value={data.phone}
                placeholder="Your contact number"
                className=""
                onChange={props.onChange}
              />
              <Form
                id="projectIdea"
                name="projectIdea"
                type="text"
                rows={2}
                value={data.projectIdea}
                placeholder="Explain about your project idea"
                className=""
                onChange={props.onChange}
              />
            </div>

            <Button
              className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
              type="button"
              onClick={submitEmail}
            >
              Submit
            </Button>
          </div>
        </Fade>
      </div>

      <ToastContainer />
    </section>
  );
}
