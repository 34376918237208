import React from 'react';
import Fade from 'react-reveal/Fade';
import * as emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Form from 'elements/Form';
import Button from 'elements/Button';

export default function DiscussForm(props) {
  const { data, resetForm } = props;

  const submitEmail = () => {
    const {
      name, company, email, phone, projectIdea,
    } = data;

    const templateParams = {
      from_name: name,
      from_company: company,
      from_email: email,
      from_contact: phone,
      message: projectIdea,
      to_name: 'DTT',
    };

    if (name !== '' && company !== '' && email !== '' && phone !== '' && projectIdea !== '') {
      emailjs.send(
        'service_6shio2e', 
        'template_0kf8djq',
        templateParams,
        'BqAp7wSUQEUaQAFfg'
      )
        .then(() => {
          toast.success("Success! We'll get back to you soon. Thank you!");
          resetForm();
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          toast.error("Error");
        });
    } else {
      toast.error('Please fill out the entire form.');
    }
  };

  return (
    <section className="flex flex-col container mx-auto mt-10 justify-center">
      <Fade bottom>
        <h1 className="text-5xl text-theme-blue text-center font-bold">Let's Discuss</h1>

        <p className="font-light text-lg text-gray-400 text-center mb-12">
        <b>Digital Think Technologies LLP</b>
        </p>

        <div className="flex flex-col">
          <div className="flex flex-col sm:flex-row mx-auto">
          <b>Mail Id</b>: devindrappa@digital-think.in

          </div>

          <div className="flex flex-col sm:flex-row mx-auto">
          <b>Address</b>: No 12, Muneshwara Block, Avalahalli, 3rd Cross, Bengaluru Urban, Karnataka 560027
          </div>

          <div className="mx-auto">
          <b>Contact No</b>:  +91 9449726429

          </div>

          <Button
            className="text-xl mx-auto px-12 py-3 mt-5 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 focus:outline-none"
            type="button"
            onClick={submitEmail}
          >
            Submit
          </Button>
        </div>
      </Fade>

      <ToastContainer />
    </section>
  );
}
